@font-face {
  font-family: "adobe-clean";
  src: url("../public/fonts/AdobeCleanExtraBold.otf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "adobe-clean";
  src: url("../public/fonts/AdobeCleanBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "adobe-clean";
  src: url("../public/fonts/AdobeCleanSemiLight.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "adobe-clean";
  src: url("../public/fonts/AdobeCleanLight.otf") format("opentype");
  font-weight: 300;
}
html, body {
    max-width: 100%;
    overflow-x: hidden;
}
body {
  background-image: url('../public/images/background.png');
  background-size: cover;
  background-attachment: fixed;
} 
* {
  font-family: 'adobe-clean';
}
.input-wrapper,select {
  border-radius: 6px;
  border: 1px solid #c9c9c9;
}
.input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.input-wrapper:has(input:focus),select:focus {
  border: 1px solid #1c53de;
  outline: 2px solid #1c53de;
}
*:focus, *:focus-visible {
  outline: 2px solid #1c53de;
}
select {
  padding: 7.5px 15px;
}
.input-svg {
  fill: #979797;
  padding: 0px 10px;
  width: 15px;
  user-select: none;
}
input {
  cursor: text;
  padding: 7.5px 15px;
  border-radius: 10px;
  border: 0;
  outline: 0;
}
input:focus,input:focus-visible {
  outline: none;
}
p {
  color: #596b76;
}
.return-button {
  display: flex;
  gap: 10px;
  font-size: 14px;
  padding: 6px 14px;
  align-items: center;
  border: 2px solid #4B4B4B;
  color: #4B4B4B;
  background-color: white;
  font-weight: bold;
  margin-bottom: 20px;
}
.return-button:hover {
  color: white;
  background-color: #4B4B4B;
}
.return-button:focus {
  outline: none;
}
.pay-now {
  padding: 10px 20px;
  background-color: #1c53de;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: 0;
  
  cursor: pointer;
  transition: 0.1s;
}
.pay-now:hover {
  background-color: #276fff;
}
.pay-now:active {
  background-color: #10449d;
}
.pay-now:focus {
  outline-color: #10449d;
}
button {
  padding: 8px 16px;
  background-color: #0367E0;
  color: white;
  border-radius: 500px;
  font-size: 18px;
  border: 0;
  font-weight: normal;
  cursor: pointer;
  transition: 0.1s;
  outline: none;
}
button:hover {
  background-color:#0059c2;
}
button:active {
  background-color: #003a7d;
}
button:focus {
  outline: none;
}
.bar {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 15px;
  margin-bottom: 15px;
}
button:disabled {
  background-color: #c2c9d1;
  color: #808d9c;
  cursor: not-allowed;
}
.question-div {
  padding: 15px;
  line-height: 20px;
  font-size: 14px;
  border-radius: 8px;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.5);
}
h1,h2, h3, h4 {
  margin: 0;
  font-weight: normal;
}
.faq-text > p {
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.faq-text > h3 {
  width: 100%;
  padding-top: 20px;
  font-size: 22px;
  border-top:1px solid #d2d2d2;
}
.loading-div {
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
h1 {
  margin-bottom: 25px;
  font-size: 48px;
  font-weight: lighter;
}
p {
  text-align: left;
}
h2 {
  text-align: left;
}
.App {
  display: flex;
  flex-direction: column;
}
.main {
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  max-width: calc(100% - 200px);
  padding: 100px;
}
.main-main {
  display: flex;
  align-self: center;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  align-items: center;
}
.payment-form {
  width: 100%;
}
.logo {
  width: 100%;
  align-self: center;
}
.flag {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  width: 60%;
  font-size: larger;
  text-align: center;
}
.flag p {
  text-align: center;
}
.flag code {
  margin-top: 20px;
  font-size: 28px;
}
.logo-div {
  margin-top: 50px;
  padding: 20px;
  width: 20%;
  align-self: center;
}
.stock-div {
  display: flex;
  flex-direction: row;
  /* margin-top: 20px; */
  color: #888888;
  gap: 20px;
}
.stock-p {
  width: fit-content;
  margin: 0;
}
round[color=green] {
  align-self: center;
  background-color: rgb(48, 163, 28);
  width: 10px;
  height: 10px;
  border-radius: 500px;
}
round[color=red] {
  align-self: center;
  background-color: rgb(163, 28, 28);
  width: 10px;
  height: 10px;
  border-radius: 500px;
}
select {
  max-width: 100%;
  min-width: 282px;
}

.input-inside {
  display: flex;
  gap: 10px;
  cursor: pointer;
}
label {
  font-weight: bold;
}
.input-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.input-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 40px;
  margin-top: 20px;
  align-items: flex-start;
}
.duration {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  width: calc(100% - 90px);
  flex-direction: column;
  padding: 40px;
  border-radius: 8px;
  max-width: 0px;
  opacity: 0;
  align-items: flex-start;
  transform: translateX(-200%);
  transition: transform ease 0.5s;
}
.inputs.shown {
  transform: translateX(calc(0% + 40px));
  max-width: 630px;
  opacity: 1;
}
.main-refund {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: white;
  align-items: center;
  justify-self: center;
  margin-top: 120px;
  padding: 50px;
  width: calc(60% - 100px);
  min-width: calc(350px - 100px);
}
.refund-container {
  display: grid;
  width: 100%;
}
label {
  height: fit-content;
  text-align: left;
  display: flex;
  align-self: center;
  align-items: center;
}
.main-main-main {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}
.payment-container {
  gap: 30px;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  width: calc(100% - 80px);
  flex-direction: column;
  padding: 40px;
  border-radius: 8px;
  align-items: flex-start;
  max-width: 0px;
  transform: translateX(200%);
  transition: transform ease 0.5s;
}
.payment-container.shown {
  transform: translateX(calc(0% - 40px));
  max-width: 700px;
  width: 625px;
  opacity: 1;
}
.payment-container-done {
  gap: 30px;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  width: calc(100% - 80px);
  flex-direction: column;
  padding: 0px;
  border-radius: 8px;
  align-items: flex-start;
  max-width: 0px;
  transform: translateX(200%);
  transition: transform ease 0.5s, padding ease 0.5s;
}
.payment-container-done.shown {
  transform: translateX(calc(0% - 80px));
  max-width: 700px;
  min-width: 600px;
  width: 625px;
  padding: 40px;
  opacity: 1;
}
.footer {
  width: calc(700px - 72px);
  display: flex;
  border: 0;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  padding: 20px 40px;
  border-style: outset;
  background-color: #ffffff;
  z-index: 10;
  justify-content: space-between;
}
.footer-inside {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.faq {
  width: calc(700px - 72px);
  display: flex;
  max-height: 60px;
  border: 0;
  border-radius: 8px;
  flex-direction: column;
  padding: 40px;
  border-style: outset;
  background-color: #ffffff;
  align-items: flex-start;
  transition: max-height 0.5s ease;
}
.success-div {
  align-self: center;
}
.faq-title {
  margin:0;
  transition: margin-bottom 0.5s ease;
}
.faq-title.withMargin {
  margin-bottom: 40px;
}
.pay-button-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px
}
.faq-text {
  opacity: 0;
  max-height: 0;
  transition: opacity 0.5s ease, max-height 0.5s ease;
}
.faq-text.shown {
  opacity: 1;
  max-height: 2000px;
}
.faq.shown {
  max-height: 1500px;
}
.reviews-list {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  gap: 20px;
}
.review-p {
  overflow-wrap: break-word;
  line-height: 30px;
  margin: 0;
  margin-top: 10px;
}
li {
  margin-bottom: 20px;
}
.review-form-container {
  width: unset;
}
.review-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 300px;
  background-color: #e9e9e9;
  padding: 20px;
  margin-top: 20px;
}
textarea {
  resize: none;
  padding: 10px;
  border: 1px solid #c9c9c9;
}
textarea:focus {
  border: 1px solid #e40000;
  box-shadow: 0px 0px 10px 0px rgb(162, 0, 0);
}
.logo {
  border: none;
}
.input-inside {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cardElement {
  cursor: text;
  padding: 0.75rem;
  border: 1px solid #dedede;
  border-radius: 5px;
  transition: border 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
  color: #d4d4d4;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}
.cardElement.focus {
  outline: none;
  color: #000000;
  box-shadow: 0 0 0 3px hsla(223, 78%, 49%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border-color: #125cdb;
}
.cardElement.cvv, .cardElement.expiry {
  outline: none;
  transition: border 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
}
.card-subwrapper {
  width: 100%;
}
.card-wrapper {
  width: 80%;
}
.payment_wrapper {
  flex-direction: row;
  gap:15;
  width:100%;
}
.payment_subwrapper {
  width: 50%;
}
.cardElement.number {
  width: -webkit-fill-available;
}
.links-div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
}
.reviews-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 5px ridge #d1d1d1;
  border-style: outset;
  width: fit-content;
  padding: 20px;
  justify-self: center;
}
@media (max-width:768px) {
  .cardElement.number {
    width: -webkit-fill-available;
  }
  .payment_wrapper {
    flex-direction: column;
    gap:15
  }
  label {
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .payment-container-done.shown {
    min-width: unset;
    width: calc(100% - 130px);
  }
  .logo {
    width: 100%;
  }
  .pay-button-div {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
  .logo-div {
    width:60%;
  }
  .main {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    max-width: 100%;
    padding-top: 50px;
  }
  .main-main {
    flex-direction: column;
    max-width: 100%;
    align-items: center;
  }
  .main-main-main, .faq {
    width: 100%;
  }
  .footer {
    width: calc(100% - 160px);
    gap: 15px;
    min-width: 283px;
  }
  .input-div {
    flex-direction: column;
  }
  .faq {
    width: calc(100% - 160px);
    min-width: 283px;
  }
}
@media (max-width:520px) {
  .faq-text>h3, .faq-text>p, .faq-text>a {
    max-width: 280px;
  }
  label {
    margin-bottom: 10px;
    margin-top: 15px;
  }
}

/* ICON SVG */

@-webkit-keyframes checkmark {
  0% {
      stroke-dashoffset: 100px
  }

  100% {
      stroke-dashoffset: 200px
  }
}

@-ms-keyframes checkmark {
  0% {
      stroke-dashoffset: 100px
  }

  100% {
      stroke-dashoffset: 200px
  }
}

@keyframes checkmark {
  0% {
      stroke-dashoffset: 100px
  }

  100% {
      stroke-dashoffset: 0px
  }
}

@-webkit-keyframes checkmark-circle {
  0% {
      stroke-dashoffset: 480px
 
  }

  100% {
      stroke-dashoffset: 960px;
    
  }
}

@-ms-keyframes checkmark-circle {
  0% {
      stroke-dashoffset: 240px
  }

  100% {
      stroke-dashoffset: 480px
  }
}

@keyframes checkmark-circle {
  0% {
      stroke-dashoffset: 480px 
  }

  100% {
      stroke-dashoffset: 960px
  }
}

@keyframes colored-circle { 
  0% {
      opacity:0
  }

  100% {
      opacity:100
  }
}

/* other styles */
/* .svg svg {
  display: none
}
*/
.inlinesvg .svg svg {
  display: inline
}

/* .svg img {
  display: none
} */

.icon--order-success svg polyline {
  -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
  animation: checkmark 0.25s ease-in-out 0.7s backwards
}

.icon--order-success svg circle {
  -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
  animation: checkmark-circle 0.6s ease-in-out backwards;
}
.icon--order-success svg circle#colored {
  -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
  animation: colored-circle 0.6s ease-in-out 0.7s backwards;
} 

button:disabled, .pay-now:disabled {
  background-color: #c2c9d1;
  color: #808d9c;
  cursor: not-allowed;
}
